/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { VictoryTheme, VictoryChart, VictoryBar } from 'victory';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
// import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import * as ANALYTICS from '../constants/analytics';
import { ContentWrapper } from './styled_components/ContentWrapper';

const styles = {
  reportContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'auto',
  },
  titleBar: {
    margin: '0 10px, 0, 0',
  },
  userHeader: {
    marginBottom: '10px',
    marginLeft: '20px',
    alignSelf: 'flex-start',
  },
  main: {
    // margin: '4vh 8px',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  leftPanel: {
    flex: 1,
    maxWidth: '625px',
    display: 'block',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#E0E0E0',
  },
  rightPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '10px',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#E0E0E0',
  },
  chartPanel: {
    minWidth: '600px',
    borderWidth: 2,
    borderColor: '#404040',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartSelectorContainer: {
    margin: '0vh 50px',
  },
  sessionsHeader: {
    marginTop: '40px',
    marginLeft: '20px',
    alignSelf: 'flex-start',
  },
  detailPanel: {
    marginTop: '20px',
    width: '90%',
    backgroundColor: 'white',
  },
  record: {
    marginLeft: '60px',
    marginBottom: '10px',
  },
  table: {
    minWidth: '35%',
  },
  tableHeader: {
    backgroundColor: '#EAFCF8',
  },
  tableRow: {},
  dateCol: {
    width: '400px',
  },
  selectedRow: {
    backgroundColor: '#EAFCF860',
  },
  smallTableCell: {
    width: '80px',
    alignText: 'right',
  },
  userEmail: {
    maxWidth: '180px',
    alignText: 'left',
  },
  expanderCol: {
    width: '40px',
    alignText: 'left',
  },
  disabledExpander: {
    color: '#F0F0F0',
  },
  eventBox: {
    marginLeft: '50px',
    backgroundColor: '#FAFAFA',
    width: '100%',
  },
  eventRow: {
    backgroundColor: '#FAFAFA',
  },
  eventCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  eventTitle: {
    fontWeight: 'bold',
  },
  eventType: {
    maxWidth: '200px',
  },
  eventDetails: {
    // width: '250px'
  },
  eventTitleDetails: {
    fontWeight: 'bold',
    // width: '250px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
};
const NUM_DAYS_IN_HISTORY = 45;

const SM_SESSION_MINUTES = 'ssm';
const LG_SESSION_MINUTES = 'lsm';
const SM_SESSION_COUNT = 'ssc';
const LG_SESSION_COUNT = 'lsc';

const userTableHeadCells = [
  {
    id: 'userId',
    numeric: false,
    disablePadding: false,
    label: 'user email',
    className: 'userEmail',
  },
  {
    id: 'activeScore',
    numeric: true,
    disablePadding: false,
    label: 'month activity (min)',
    className: 'smallTableCell',
  },
  {
    id: 'sinceLast',
    numeric: true,
    disablePadding: false,
    label: 'last seen (days)',
    className: 'smallTableCell',
  },
  {
    id: 'sinceFirst',
    numeric: true,
    disablePadding: false,
    label: 'member for (days)',
    className: 'smallTableCell',
  },
];

const sessionTableHeadCells = [
  {
    id: 'expander',
    numeric: false,
    disablePadding: false,
    label: '-',
    width: '200px',
    sortable: false,
    className: 'expanderCol',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'date',
    width: '200px',
    className: 'dateCol',
  },
  {
    id: 'duration',
    numeric: true,
    disablePadding: true,
    label: 'duration (min)',
  },
  {
    id: 'browser',
    numeric: false,
    disablePadding: false,
    label: 'browser',
  },
  {
    id: 'os',
    numeric: false,
    disablePadding: false,
    label: 'os',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'location',
  },
  {
    id: 'projects',
    numeric: true,
    disablePadding: true,
    label: 'own proj',
    className: 'smallTableCell',
  },
  {
    id: 'other',
    numeric: true,
    disablePadding: true,
    label: 'other proj',
    className: 'smallTableCell',
  },
  {
    id: 'templates',
    numeric: true,
    disablePadding: true,
    label: 'own workfws',
    className: 'smallTableCell',
  },
  {
    id: 'shares',
    numeric: true,
    disablePadding: true,
    label: 'shares',
    className: 'smallTableCell',
  },
  {
    id: 'events',
    numeric: true,
    disablePadding: true,
    label: 'events',
    className: 'smallTableCell',
  },
];

const CHART_TYPE_MINS = 'mins';
const CHART_TYPE_SESSIONS = 'sessions';
const CHART_TYPE_MIN_PER_SESSION = 'mps';
const CHART_PARMS = {
  [CHART_TYPE_MINS]: { maxY: 120 },
  [CHART_TYPE_SESSIONS]: { maxY: 10 },
  [CHART_TYPE_MIN_PER_SESSION]: { maxY: 60 },
};

class AnalyticsReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      error: null,
      order: 'desc',
      orderBy: 'sinceLast',
      // orderBy: 'user',
      sessionOrder: 'asc',
      // sessionOrder: 'desc',
      sessionOrderBy: 'date',
      dense: false,
      curSelectedUserIndex: 0,
      curSelectedSessionIndex: 0,
      chartType: 'mins',
    };
  }

  handleClickOnUser = (event, newIndex) => {
    this.setState({
      curSelectedUserIndex: newIndex,
      sessionOrder: 'desc',
      sessionOrderBy: 'date',
    });
  };

  handleClickOnSession = (event, newIndex) => {
    this.setState({ curSelectedSessionIndex: newIndex });
  };

  handleChartChange = (event, type) => {
    this.setState({ chartType: type });
  };

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => -this.descendingComparator(a, b, orderBy)
      : (a, b) => this.descendingComparator(a, b, orderBy);
  };

  stableSort = (array, property, order) => {
    const stabilizedThis = array.map((el, index) => [el, index]); // ensure complete data for sort algorithm
    const comparator = this.getComparator(order, property);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  sortUserTable = property => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    const newArray = this.stableSort(this.state.users, property, newOrder);

    this.setState({
      users: newArray,
      curSelectedUserIndex: 0,
      order: newOrder,
      orderBy: property,
      sessionOrder: 'desc',
      sessionOrderBy: 'date',
    });
  };

  sortSessionTable = property => {
    const isAsc = this.state.sessionOrderBy === property && this.state.sessionOrder === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    const newArray = this.state.users.slice();
    newArray[this.state.curSelectedUserIndex].data.sessions = this.stableSort(
      newArray[this.state.curSelectedUserIndex].data.sessions,
      property === 'date' ? 'ts' : property,
      newOrder,
    );

    this.setState({
      users: newArray,
      curSelectedSessionIndex: 0,
      sessionOrder: newOrder,
      sessionOrderBy: property,
    });
  };

  renderTableHeadCell = props => {
    const { classes, order, orderBy, headCells, isUsersTable } = props;

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell, index) => {
            let tableCellClassName;
            switch (headCell.className) {
              case 'smallTableCell':
                tableCellClassName = classes.smallTableCell;
                break;
              case 'expanderCol':
                tableCellClassName = classes.expanderCol;
                break;
              case 'userEmail':
                tableCellClassName = classes.userEmail;
                break;
              case 'dateCol':
                tableCellClassName = classes.dateCol;
                break;
              default:
                tableCellClassName = classes.smallTableCell;
            }
            return (
              <TableCell
                key={headCell.id}
                align={(isUsersTable && index === 0) || (!isUsersTable && index === 1) ? 'left' : 'right'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                className={tableCellClassName}
              >
                {headCell.sortable === undefined || headCell.sortable ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={event => {
                      if (isUsersTable) {
                        this.sortUserTable(headCell.id); // headCell.id holds the property name
                      } else {
                        this.sortSessionTable(headCell.id); // headCell.id holds the property name
                      }
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : null}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  render = () => {
    const { classes } = this.props;

    console.log(`rendering analytics ..... ${new Date().getTime()}`);

    if (this.state.users) {
      let chartData;
      let chartTitle = 'nothing';
      switch (this.state.chartType) {
        case CHART_TYPE_MINS:
          chartTitle = 'Minutes';
          chartData = this.state.users[this.state.curSelectedUserIndex].data.history[LG_SESSION_MINUTES].map(
            (value, index) => {
              return { x: index, y: value };
            },
          );
          break;
        case CHART_TYPE_SESSIONS:
          chartTitle = 'Sessions';
          chartData = this.state.users[this.state.curSelectedUserIndex].data.history[LG_SESSION_COUNT].map(
            (value, index) => {
              return { x: index, y: value };
            },
          );
          break;
        case CHART_TYPE_MIN_PER_SESSION:
          chartTitle = 'Minutes/Session ';
          chartData = this.state.users[this.state.curSelectedUserIndex].data.history[LG_SESSION_COUNT].map(
            (value, index) => {
              return {
                x: index,
                y:
                  value === 0
                    ? 0
                    : Math.ceil(
                        this.state.users[this.state.curSelectedUserIndex].data.history[LG_SESSION_MINUTES][
                          index
                        ] / value,
                      ),
              };
            },
          );
          break;
        default: // to get rid of eslint warnings
      }

      return (
        <ContentWrapper title="Activity Summary">
          <div className={classes.reportContainer}>
            <div className={classes.main}>
              <div className={classes.leftPanel}>
                <h3 className={classes.userHeader}>Users</h3>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={this.state.dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                  >
                    {this.renderTableHeadCell({
                      headCells: userTableHeadCells,
                      classes: classes,
                      order: this.state.order,
                      orderBy: this.state.orderBy,
                      isUsersTable: true,
                    })}
                    <TableBody>
                      {this.state.users.map((row, index) => {
                        return (
                          <TableRow
                            className={
                              index === this.state.curSelectedUserIndex
                                ? classes.selectedRow
                                : classes.tableRow
                            }
                            // hover
                            onClick={event => this.handleClickOnUser(event, index)}
                            tabIndex={-1}
                            key={row.email}
                          >
                            <TableCell component="th" className={classes.userEmail} id={'email'} scope="row">
                              {row.userId.length > 25 ? `${row.userId.substring(0, 25)}...` : row.userId}
                            </TableCell>
                            <TableCell className={classes.smallTableCell} align="right">
                              {row.activeScore}
                            </TableCell>
                            <TableCell className={classes.smallTableCell} align="right">
                              {-row.sinceLast}
                            </TableCell>
                            <TableCell className={classes.smallTableCell} align="right">
                              {row.sinceFirst}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className={classes.rightPanel}>
                <h3 className={classes.userHeader}>{`${
                  this.state.users[this.state.curSelectedUserIndex].userId
                }`}</h3>
                {/* <div className={classes.chartPanel}>
                  <div className="classes.chartSelectorContainer">
                    <Radio
                      checked={
                        this.state.chartType === CHART_TYPE_MINS
                      }
                      onChange={event =>
                        this.handleChartChange(event, CHART_TYPE_MINS)
                      }
                      value="a"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'Min' }}
                    />
                    <Radio
                      checked={
                        this.state.chartType === CHART_TYPE_SESSIONS
                      }
                      onChange={event =>
                        this.handleChartChange(
                          event,
                          CHART_TYPE_SESSIONS,
                        )
                      }
                      value="b"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'Session' }}
                    />
                    <Radio
                      checked={
                        this.state.chartType ===
                        CHART_TYPE_MIN_PER_SESSION
                      }
                      onChange={event =>
                        this.handleChartChange(
                          event,
                          CHART_TYPE_MIN_PER_SESSION,
                        )
                      }
                      value="e"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'm/S' }}
                    />
                  </div>
                  <h4>{chartTitle}</h4>
                  <VictoryChart
                    theme={VictoryTheme.material}
                    key={`${
                      this.state.users[
                        this.state.curSelectedUserIndex
                      ].userId
                    }-${this.state.chartType}`} // forces redraw on user or chart type change
                    domain={{
                      x: [0, NUM_DAYS_IN_HISTORY],
                      y: [0, CHART_PARMS[this.state.chartType].maxY],
                    }}
                    height={400}
                    width={700}
                  >
                    <VictoryBar
                      style={{
                        data: { stroke: '#c43a31' },
                        parent: { border: '1px solid #ccc' },
                      }}
                      key={`${this.state.curSelectedUserIndex}-${this.state.chartType}`}
                      data={chartData}
                    />
                  </VictoryChart>
                </div> */}
                <h3 className={classes.sessionsHeader}>{`Sessions`}</h3>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={this.state.dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                  >
                    {this.renderTableHeadCell({
                      headCells: sessionTableHeadCells,
                      classes: classes,
                      order: this.state.sessionOrder,
                      orderBy: this.state.sessionOrderBy,
                      isUsersTable: false,
                    })}
                    <TableBody>
                      {this.state.users[this.state.curSelectedUserIndex].data.sessions.map((row, index) => {
                        return (
                          <Fragment>
                            <TableRow
                              className={
                                index === this.state.curSelectedSessionIndex
                                  ? classes.selectedRow
                                  : classes.tableRow
                              }
                              // hover
                              onClick={event => this.handleClickOnSession(event, index)}
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell className={classes.expanderCol}>
                                {row.data.events && row.data.events.length > 0 ? (
                                  <IconButton
                                    aria-label="show events"
                                    size="small"
                                    onClick={() => (row.expanded = !row.expanded)}
                                  >
                                    {row.expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    aria-label="no events"
                                    size="small"
                                    className={classes.disabledExpander}
                                  >
                                    {<DragHandleIcon />}
                                  </IconButton>
                                )}
                              </TableCell>
                              <TableCell component="th" className={classes.dateCol} id={'date'} scope="row">
                                {row.date}
                              </TableCell>
                              <TableCell align="right">{row.duration}</TableCell>
                              <TableCell align="right">{row.browser}</TableCell>
                              <TableCell align="right">{row.os}</TableCell>
                              <TableCell align="right">{row.location}</TableCell>
                              <TableCell className={classes.smallTableCell} align="right">
                                {row.projects}
                              </TableCell>
                              <TableCell className={classes.smallTableCell} align="right">
                                {row.others}
                              </TableCell>
                              <TableCell className={classes.smallTableCell} align="right">
                                {row.templates}
                              </TableCell>
                              <TableCell className={classes.smallTableCell} align="right">
                                {row.shares}
                              </TableCell>
                              <TableCell className={classes.smallTableCell} align="right">
                                {row.events}
                              </TableCell>
                            </TableRow>
                            <TableRow className={classes.eventRow}>
                              <TableCell className={classes.eventCell} colSpan={10}>
                                <Collapse in={row.expanded} timeout="auto" unmountOnExit>
                                  <Box margin={1} className={classes.eventBox}>
                                    <Table size="small" aria-label="events">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className={classes.eventTitleDetails}>Events</TableCell>
                                          <TableCell className={classes.eventTitle} align="right">
                                            Time
                                          </TableCell>
                                          <TableCell className={classes.eventTitleDetails}>Details</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {row.data.events && row.data.events.length > 0
                                          ? row.data.events.map(event => {
                                              let detail = '';
                                              if (event.data.project)
                                                detail += `project "${event.data.project}" `;
                                              if (event.data.deliverable)
                                                detail += `del "${event.data.deliverable}" `;
                                              if (event.data.workPackage)
                                                detail += `wp "${event.data.workPackage}" `;
                                              return (
                                                <TableRow key={event.ts}>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.eventType}
                                                  >
                                                    {event.type}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {this.convertFrom24To12Format(event.ts.substring(11, 16))}
                                                  </TableCell>
                                                  <TableCell className={classes.eventDetails}>
                                                    {detail}
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })
                                          : null}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </ContentWrapper>
      );
    } else {
      return (
        <ContentWrapper title="Activity Summary">
          <p>loading...</p>
          <CircularProgress />
        </ContentWrapper>
      );
    }
  };

  generateString = (type, date, time, data) => {
    switch (type) {
      case ANALYTICS.SESSION_RECORD:
        return `${type} - ${date}, ${time} - ${data.browser?.name}/${data.browser?.os} - ${data.geo?.city},${data.geo?.countryCode} - ${data.duration} min - ${data.content?.numCreatedProjects}/${data.content?.numOtherProjects}/${data.content?.numCreatedTemplates}/${data.content?.uniqueShares} `;
      case ANALYTICS.SIGNUP_RECORD:
        return `${type} - ${date}, ${time} signed up`;
      default:
    }
  };
  convertFrom24To12Format = time24 => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'am' : 'pm';
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };
  componentDidMount = () => {
    const { firebase } = this.props;

    const thisComponent = this;
    const timeBeforeWeekId = moment()
      .subtract(NUM_DAYS_IN_HISTORY, 'days')
      .format('GGGG-WW');

    const askTime = new Date().getTime();
    console.log(`getting analytics from server... ${askTime}`);

    firebase.db
      .collection('analytics')
      .where('weekId', '>=', timeBeforeWeekId)
      .get()
      .then(querySnapshot => {
        console.log(
          `analytics have data from server ${new Date().getTime()} ${moment(new Date().getTime()).diff(
            moment(askTime),
            'millisecond',
          )}`,
        );

        let docs = [];
        querySnapshot.forEach(doc => {
          let docData = doc.data();
          docData.id = doc.id;
          docs.push(docData);
        });

        let users = {};
        const zeroHistoryArray = [];
        for (let i = 0; i < NUM_DAYS_IN_HISTORY; i++) zeroHistoryArray.push(0);
        // process docs
        docs.forEach(doc => {
          Object.keys(doc.records).forEach(recordId => {
            const recordData = doc.records[recordId];
            // if(recordData.duration) { // if valid record
            const emailRef = recordData.userEmail ? recordData.userEmail : 'undefined user email';
            let tsDate;
            let tsTime24;
            let tsTimeAP;
            if (!users[emailRef])
              users[emailRef] = {
                history: {
                  [SM_SESSION_MINUTES]: zeroHistoryArray.slice(),
                  [SM_SESSION_COUNT]: zeroHistoryArray.slice(),
                  [LG_SESSION_MINUTES]: zeroHistoryArray.slice(),
                  [LG_SESSION_COUNT]: zeroHistoryArray.slice(),
                },
                sessions: [],
                stats: {
                  daysSinceFirstSession: null,
                  daysSinceLastSession: null,
                },
              };
            let localDayFromTS;
            let localTimeFromTS;
            const now = moment();
            let numDaysFromToday;
            switch (recordData.recType) {
              case ANALYTICS.SESSION_RECORD:
                localDayFromTS = recordData.data.startTS.substring(0, 10);
                localTimeFromTS = recordData.data.startTS.substring(11, 16);
                numDaysFromToday = now.diff(moment(localDayFromTS), 'days'); // === number of days in the past
                if (
                  users[emailRef].stats.daysSinceFirstSession === null ||
                  numDaysFromToday > users[emailRef].stats.daysSinceFirstSession
                )
                  users[emailRef].stats.daysSinceFirstSession = numDaysFromToday;
                if (
                  users[emailRef].stats.daysSinceLastSession === null ||
                  numDaysFromToday < users[emailRef].stats.daysSinceLastSession
                )
                  users[emailRef].stats.daysSinceLastSession = numDaysFromToday;

                if (numDaysFromToday < NUM_DAYS_IN_HISTORY) {
                  if (recordData.data.duration <= 0) {
                    // currently don't use small session differentiation
                    users[emailRef].history[SM_SESSION_MINUTES].splice(
                      numDaysFromToday,
                      1,
                      users[emailRef].history[SM_SESSION_MINUTES][numDaysFromToday] +
                        recordData.data.duration,
                    );
                    users[emailRef].history[SM_SESSION_COUNT].splice(
                      numDaysFromToday,
                      1,
                      users[emailRef].history[SM_SESSION_COUNT][numDaysFromToday] + 1,
                    );
                  } else {
                    users[emailRef].history[LG_SESSION_MINUTES].splice(
                      numDaysFromToday,
                      1,
                      users[emailRef].history[LG_SESSION_MINUTES][numDaysFromToday] +
                        recordData.data.duration,
                    );
                    users[emailRef].history[LG_SESSION_COUNT].splice(
                      numDaysFromToday,
                      1,
                      users[emailRef].history[LG_SESSION_COUNT][numDaysFromToday] + 1,
                    );
                  }
                }
                tsDate = moment(localDayFromTS).format(`MMM-DD-YYYY`);
                tsTime24 = localTimeFromTS;
                tsTimeAP = this.convertFrom24To12Format(localTimeFromTS);
                users[emailRef].sessions.push({
                  ts: moment(recordData.data.startTS),
                  time24: tsTime24,
                  timeAP: tsTimeAP,

                  // below are values for table presentation
                  date: `${tsDate}, ${tsTimeAP}`,
                  browser: recordData.data?.browser?.name,
                  os: recordData.data?.browser?.os,
                  location: `${recordData.data.geo?.city}, ${recordData.data.geo?.countryCode}`,
                  duration: recordData.data.duration,
                  projects: recordData.data.content?.numCreatedProjects,
                  others: recordData.data.content?.numOtherProjects,
                  templates: recordData.data.content?.numCreatedTemplates,
                  shares: recordData.data.content?.uniqueShares,
                  events: recordData.data.events?.length,
                  // general session data
                  data: recordData.data,
                  expanded: false,
                  // old report string... can get rid of in future
                  stringified: this.generateString(recordData.recType, tsDate, tsTimeAP, recordData.data),
                });
                break;
              case ANALYTICS.SIGNUP_RECORD:
                localDayFromTS = recordData.timestamp.substring(0, 10);
                localTimeFromTS = recordData.timestamp.substring(11, 16);
                numDaysFromToday = now.diff(moment(localDayFromTS), 'days'); // === number of days in the past
                if (
                  users[emailRef].stats.daysSinceFirstSession === null ||
                  numDaysFromToday > users[emailRef].stats.daysSinceFirstSession
                )
                  users[emailRef].stats.daysSinceFirstSession = numDaysFromToday;
                if (
                  users[emailRef].stats.daysSinceLastSession === null ||
                  numDaysFromToday < users[emailRef].stats.daysSinceLastSession
                )
                  users[emailRef].stats.daysSinceLastSession = numDaysFromToday;

                tsDate = moment(localDayFromTS).format(`MMM-DD-YYYY`);
                tsTime24 = localTimeFromTS;
                tsTimeAP = this.convertFrom24To12Format(localTimeFromTS);
                const unpackedData = JSON.parse(recordData.data);
                users[emailRef].sessions.push({
                  ts: moment(recordData.timestamp),
                  date: tsDate,
                  time24: tsTime24,
                  timeAP: tsTimeAP,
                  data: recordData.data,
                  duration: 'new account signup',
                  browser: unpackedData?.browser,
                  os: unpackedData?.os,
                  stringified: this.generateString(
                    recordData.recType,
                    tsDate,
                    tsTimeAP,
                    recordData.data?.user,
                    unpackedData?.browser,
                    unpackedData?.os,
                  ),
                });
                break;
              default:
                //do nothing
                console.log(`unhandled analytics record type "${recordData.recType}"`);
            }
            // }
          });
        });
        // if(Object.keys(users).length > 0) {
        Object.keys(users).forEach(userId => {
          // sort sessions in time order - not a relevant in future as table will sort under user control
          users[userId].sessions.sort((a, b) => {
            if (a.ts.isBefore(b.ts)) return 1;
            if (b.ts.isBefore(a.ts)) return -1;
            return 0;
          });
        });
        // turn users hash into array and sort by userid
        let userArray = Object.keys(users).map(userId => {
          let activityScore = 0;
          for (let t = 0; t < NUM_DAYS_IN_HISTORY; t++) {
            activityScore +=
              users[userId].history[LG_SESSION_MINUTES][t] + users[userId].history[SM_SESSION_MINUTES][t];
          }
          return {
            userId: userId,
            activeScore: activityScore,
            sinceLast: -users[userId].stats.daysSinceLastSession,
            sinceFirst: users[userId].stats.daysSinceFirstSession,
            data: users[userId],
          };
        });
        userArray.sort((a, b) => {
          if (a.userId < b.userId) return -1;
          if (a.userId > b.userId) return 1;
          return 0;
        });
        thisComponent.setState({
          users: userArray,
          curSelectedUserIndex: 0,
        });
        // }
      })
      .catch(function(err) {
        console.warn(`cannot load analytics docs from server: ${err}`);
        thisComponent.setState({
          error: `cannot load analytics docs from server: ${err}`,
        });
        alert(`cannot load analytics docs from server: ${err}`);
      });
  };
}

export default connect(state => {
  return {
    firebase: state.firebase,
  };
}, {})(withStyles(styles)(AnalyticsReports));
