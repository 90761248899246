// @flow
/* eslint-disable */
import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  withStyles,
  makeStyles,
  Tooltip,
  Badge,
  Typography,
  Menu,
  MenuItem,
  Select,
} from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFileOutlined';
import GridOn from '@material-ui/icons/GridOn';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ListIcon from '@material-ui/icons/List';
import SplitWPDialog from './SplitWPDialog';
import SprintColumn from './SprintColumn';
import OnboardingDialog from '../OnboardingDialog';
import NamePickerDialog from '../NamePickerDialog.js';

import { ContentWrapper } from '../styled_components/ContentWrapper';
import { AddButton } from '../styled_components/AddButton';
import ProjectScheduleReport from '../project_overview/ProjectScheduleReport';

import {
  handleMenu,
  reorderColumns,
  dispatchUpdateViewState,
  setSprintPeriod,
} from '../../data/actions/userInterface';
import { togglePinVisibility, toggleDetailPinVisibility } from '../../data/actions/app';
import { saveCurrentProject, updateViewState } from '../../data/actions/userInterface';
import { dispatchUpdateUsibilityReport } from '../../data/actions/app';

import * as utils from '../../utils/generalUtilities.js';
import * as NAV from '../../constants/navigation';
import * as STATUS from '../../constants/status';
import * as USER from '../../constants/user';
import * as constants from '../../constants/app';
import { TIME_FILTER_1MONTH } from '../../constants/tasks';
import { PriorityHigh } from '@material-ui/icons';

const assignedToAvatarSize = 25;

const styles = {
  root: {
    paddingBottom: '8px', // To give some breathing room with the scroll bar
    display: 'flex',
    justifyContent: 'flex-start',
    // overflowX: 'scroll',
    // overflowY: 'hidden',
    overflow: 'hidden',
    maxHeight: 'calc(100% - 120px)',
  },
  collapsed: {
    height: '0',
  },
  addButton: {
    marginLeft: 0,
    marginRight: 20,
    color: '#FFF',
  },
  attachIcon: {
    height: '30px',
    width: '30px',
    border: '2px solid #aaa',
    borderRadius: '5px',
    marginLeft: '30px',
    padding: '2px',
    color: '#888',
    backgroundColor: '#f6f6f6',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
  },
  assignedToAvatarText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
};

const SPRINT_PERIODS = ['1 week', '2 weeks', '4 weeks'];
const NOT_ASSIGNED = 'not assigned';
const NOT_ASSIGNED_BKGD_COLOR = '#A0A0A020';
class SprintView extends React.Component {
  constructor(props) {
    super(props);

    const teamViz = { [props.project.creator]: false };
    props.project.sharedWith.forEach(member => {
      teamViz[member] = false;
    });
    Object.keys(props.sprintData).forEach(sprintId => {
      props.sprintData[sprintId].forEach(wpCard => {
        if (wpCard.workpackage?.assignedTo && wpCard.workpackage.assignedTo !== '') {
          teamViz[wpCard.workpackage.assignedTo] = true;
        }
      });
    }, {});
    props.noSprintWPCards.forEach(wpCard => {
      if (wpCard.workpackage?.assignedTo && wpCard.workpackage.assignedTo !== '') {
        teamViz[wpCard.workpackage.assignedTo] = true;
      }
    });
    teamViz[NOT_ASSIGNED] = true;
    this.state = {
      teamModel: false,
      attachmentsOpen: false,
      onboardingDialog: Boolean(
        props.userPrefs.onboarding && props.userPrefs.onboarding[USER.ONBOARDING_SPRINTVIEW_DIALOG],
      ),
      menuOpen: false,
      sprintPeriod: `${props.viewState.numWeeksInSprint} week${
        props.viewState.numWeeksInSprint === 1 ? '' : 's'
      }`,
      numVisibleMonths: 3,
      teamVisibility: teamViz,
      editor: { mode: 'off' },
    };
  }
  setAssignmentPickerDialog = value => {
    this.setState({ editor: value });
  };

  setOnboardingDialog = value => {
    this.setState({ onboardingDialog: value });
  };

  onDragEnd = result => {
    const { noSprintWPCards, sprintData, project, saveCurrentProject, viewState } = this.props;

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    console.log(
      `item drop details - source ${source.droppableId} ${source.index} - ${destination.droppableId} ${destination.index}`,
    );
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    let sourceArray;
    // with filtering on, we cannot use the source.index directly on the arrays, so have to look up the item's index in the array
    if (source.droppableId === constants.NO_SPRINT) {
      sourceArray = noSprintWPCards ?? [];
    } else {
      sourceArray = sprintData[source.droppableId] ?? [];
    }
    let searchSourceArray = this.filterByVisibility(sourceArray);
    const indexInTheSourceCardArray = sourceArray.findIndex(wpCard => {
      return wpCard.uuid === searchSourceArray[source.index].uuid;
    });

    let destArray;
    if (destination.droppableId === constants.NO_SPRINT) {
      destArray = noSprintWPCards ?? [];
    } else {
      destArray = sprintData[destination.droppableId] ?? [];
    }
    let searchDestArray = this.filterByVisibility(destArray);
    let indexInTheDestCardArray = destArray.findIndex(wpCard => {
      if (!searchDestArray[destination.index]) return false; // past end of existing array
      return wpCard.uuid === searchDestArray[destination.index].uuid;
    });
    if (indexInTheDestCardArray < 0) indexInTheDestCardArray = destArray.length;

    const destSprintColId = destination.droppableId;
    const destDropOffset =
      destination.droppableId === source.droppableId && indexInTheDestCardArray > indexInTheSourceCardArray
        ? -1
        : 0;
    if (
      !utils.setWPSprint(
        project,
        sourceArray[indexInTheSourceCardArray],
        destSprintColId,
        indexInTheDestCardArray + (destDropOffset === 0 ? -0.5 : 0.5),
        viewState.numWeeksInSprint,
      )
    ) {
      return;
    }
    // update sprintOrder in WPs
    const moveWPCard = sourceArray.splice(indexInTheSourceCardArray, 1)[0];
    destArray.splice(indexInTheDestCardArray + destDropOffset, 0, moveWPCard);
    utils.sprintWPCardsOrderSort(destArray);
    saveCurrentProject(project);
  };

  generateTeamMemberAvatarButton = (teamMemberEmail, visible) => {
    if (
      teamMemberEmail !== NOT_ASSIGNED &&
      (!this.props.userPrefs.collegues[teamMemberEmail] ||
        !this.props.userPrefs.collegues[teamMemberEmail].color)
    ) {
      this.props.userPrefs.collegues[teamMemberEmail] = utils.randColor();
      setTimeout(() => {
        setUserColleguePref({
          email: teamMemberEmail,
          color: utils.randColor(),
        });
      });
    }
    const avatarBkgdColor =
      teamMemberEmail === NOT_ASSIGNED || !this.props.userPrefs.collegues[teamMemberEmail]?.color
        ? visible
          ? NOT_ASSIGNED_BKGD_COLOR.substring(0, 7) + 'D0'
          : NOT_ASSIGNED_BKGD_COLOR.substring(0, 7) + '30'
        : visible
        ? this.props.userPrefs.collegues[teamMemberEmail].color
        : this.props.userPrefs.collegues[teamMemberEmail].color.substring(0, 7) + '30';

    return (
      <Tooltip title={teamMemberEmail}>
        <div
          // style={[styles.assignedToAvatar, { backgroundColor: avatarBkgdColor, marginLeft: '10px' }]}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: assignedToAvatarSize / 2,
            height: assignedToAvatarSize,
            width: assignedToAvatarSize,
            backgroundColor: avatarBkgdColor,
            marginLeft: '10px',
            userSelect: 'none',
          }}
          onClick={e => {
            const newTeamViz = { ...this.state.teamVisibility };
            if (e.shiftKey) {
              Object.keys(newTeamViz).forEach(itemEmail => {
                if (itemEmail === teamMemberEmail) newTeamViz[itemEmail] = true;
                else newTeamViz[itemEmail] = false;
              });
            } else {
              newTeamViz[teamMemberEmail] = !newTeamViz[teamMemberEmail];
            }
            this.setState({ teamVisibility: newTeamViz });
          }}
        >
          <Typography
            style={{
              color: 'white',
              fontSize: 18,
              fontWeight: 700,
              alignSelf: 'center',
            }}
          >
            {teamMemberEmail === NOT_ASSIGNED ? '-' : teamMemberEmail.substring(0, 1).toUpperCase()}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  filterByVisibility = wpCards => {
    const result = [];
    wpCards.forEach(wpCard => {
      if (
        wpCard.workpackage?.assignedTo && //
        wpCard.workpackage.assignedTo !== '' &&
        this.state.teamVisibility[wpCard.workpackage.assignedTo]
      ) {
        result.push(wpCard);
      }
      if (
        (!wpCard.workpackage?.assignedTo || wpCard.workpackage.assignedTo === '') &&
        this.state.teamVisibility[NOT_ASSIGNED]
      ) {
        result.push(wpCard);
      }
    });
    return result;
  };

  render() {
    const {
      classes,
      project,
      projects,
      user,
      userPrefs,
      withScrollableColumns,
      isCombineEnabled,
      workflows,
      viewState,
      viewStack,
      showListViewDialog,
      noSprintWPCards,
      sprintData,
      zoomDelUID,
      togglePinVisibility,
      toggleDetailPinVisibility,
      setSprintPeriod,
      dispatchUpdateViewState,
    } = this.props;

    const splitWP = viewState.splitWP;
    let pinVisibility = viewState.showUnpinned;
    let changePinVisibility = togglePinVisibility;
    utils.clearAllSelections();

    // sprintData holds all the wpCards in each sprint as well as a special entry for pinned TG's with not work period set

    const numVisibleWorkPeriods = (this.state.numVisibleMonths * 4) / viewState.numWeeksInSprint;

    const earliestSprintId = Object.keys(sprintData).reduce((acc, sprintId) => {
      const wpCard = sprintData[sprintId];
      if (sprintId < acc) {
        return sprintId;
      }
      return acc;
    }, '3000-01');
    const startIndex = utils.getSprintOffset(earliestSprintId, null, viewState.numWeeksInSprint);
    if (startIndex > 0) startIndex = 0;
    const colSprintIds = [];
    const showMoreWorkPeriodStr = ` - next 3 months -`;
    for (let i = startIndex; i < numVisibleWorkPeriods; i++) {
      let colSprintId = utils.getRelativeSprintId(viewState.numWeeksInSprint, i);
      colSprintIds.push(colSprintId);
    }
    colSprintIds.push(showMoreWorkPeriodStr);

    let columns = colSprintIds.map((sprintId, index) => {
      if (sprintId === showMoreWorkPeriodStr) {
        return (
          <div key={`wpc-button`}>
            <div
              style={{
                width: '300px',
                height: '1000px',
                // backgroundColor: 'yellow',
              }}
            >
              <Button
                onClick={() => this.setState({ numVisibleMonths: this.state.numVisibleMonths + 3 })}
                variant="contained"
                disabled={false}
                size="large"
                style={{
                  marginTop: '80px',
                  marginLeft: '30px',
                  width: '160px',
                  backgroundColor: '#52d1b5',
                  color: '#fff',
                  fontWeight: 700,
                }}
                className={classes.buttonContainer}
              >
                show 3 more months
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <SprintColumn
            key={`wpc-${sprintId}`}
            index={index}
            sprintId={sprintId}
            sprint={this.filterByVisibility(sprintData[sprintId] ?? [])}
            isScrollable={withScrollableColumns}
            isCombineEnabled={isCombineEnabled}
            isExpanded={true}
            showDetail={false}
            viewStack={viewStack}
            setEditor={this.setAssignmentPickerDialog}
          />
        );
      }
    });

    let itemType;
    if (this.state.editor.item) {
      itemType = this.state.editor.item.workPackages
        ? 'Deliverable'
        : (itemType = this.state.editor.item.tasks ? 'Task Group' : 'Task');
    }
    return (
      <Fragment>
        <ContentWrapper
          title={NAV.SPRINTVIEW}
          // title={`Schedule`}
          handleMenu={this.props.handleMenu}
          dispatchUpdateViewState={dispatchUpdateViewState}
          viewState={this.props.viewState}
          headerMarginTop={10}
          maxTitleCardHeight={'60px'}
          titleChildren={
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Tooltip title={'Schedule'}>
                <ListIcon
                  // <AssessmentOutlinedIcon
                  className={classes.attachIcon}
                  onClick={e => {
                    // toggleAttachmentsOpen();
                    dispatchUpdateUsibilityReport();
                    Object.assign(viewState, {
                      curWindow: NAV.SCHEDULE_REPORT,
                      lastWindow: { ...viewState.lastWindow, [viewState.curProjectUUID]: NAV.SPRINTVIEW },
                    });
                    dispatchUpdateViewState(viewState);
                  }}
                />
              </Tooltip>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'flex-start',
                  height: '40px',
                  marginRight: '30px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'flex-start',
                    height: '40px',
                    marginRight: '30px',
                    marginTop: '3px',
                  }}
                >
                  {Object.keys(this.state.teamVisibility).reduce((acc, email, index) => {
                    if (email !== NOT_ASSIGNED) {
                      acc.push(this.generateTeamMemberAvatarButton(email, this.state.teamVisibility[email]));
                    }
                    return acc;
                  }, [])}
                  {this.generateTeamMemberAvatarButton(NOT_ASSIGNED, this.state.teamVisibility[NOT_ASSIGNED])}
                </div>
                <Typography
                  variant="h6"
                  style={{ alignSelf: 'center', marginRight: '20px', height: '40px' }}
                  noWrap
                >
                  work period
                </Typography>
                {/* <Tooltip title={'set sprint period'}> */}
                <Select
                  id="sprint-period-select"
                  name="sprint-period"
                  // labelId="workpackage-priority-label"
                  classes={{ root: classes.menuItem }}
                  value={this.state.sprintPeriod}
                  onChange={e => {
                    this.setState({ sprintPeriod: e.target.value });
                    setSprintPeriod(parseInt(e.target.value.substring(0, 1)));
                  }}
                  variant="outlined"
                >
                  {SPRINT_PERIODS.map(option => {
                    return (
                      <MenuItem key={`sprint-${option}`} className={classes.menuItem} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* </Tooltip> */}
              </div>
            </div>
          }
        >
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div
              className={`${classes.root}`}
              aria-expanded={true}
              onScroll={e => {
                if (e.currentTarget.scrollLeft !== viewState.scrollTop.wbsLeft) {
                  if (scrollTimer) {
                    clearTimeout(scrollTimer);
                  }
                  const scrollLeft = e.currentTarget.scrollLeft;
                  scrollTimer = setTimeout(e => {
                    viewState.scrollTop.wbsLeft = scrollLeft;
                    updateViewState(viewState);
                    scrollTimer = undefined;
                  }, 50);
                }
              }}
            >
              <SprintColumn
                key={`wpc-noSprint`}
                index={-1}
                sprintId={constants.NO_SPRINT}
                sprint={this.filterByVisibility(noSprintWPCards)}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                isExpanded={true}
                showDetail={false}
                viewStack={viewStack}
                setEditor={this.setAssignmentPickerDialog}
              />

              <div
                style={{
                  alignSelf: 'stretch',
                  width: '8px',
                  marginLeft: '-2px',
                  marginRight: '2px',
                  backgroundColor: '#8884',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflowY: 'hidden',
                  overflowX: 'scroll', // scrollview
                }}
              >
                {columns}
              </div>
            </div>
          </DragDropContext>
          {this.state.onboardingDialog && (
            <OnboardingDialog
              title={'The Timeline'}
              text={[
                'Use this screen to schedule your Task Groups that are in progress or have been pinned.',
                'Select a work period at the top right, then drag Task Group cards to the desired calendar column.',
                'Click the View Schedule icon at the top for a more detailed report. You can also download the Schedule as a PDF from here.',
              ]}
              links={null}
              // links={[
              //   { text: 'here', link: 'https://www.youtube.com/watch?v=ebxVHKuNRmI' },
              //   { text: 'here', link: 'https://www.youtube.com/watch?v=xlVpS7cbJN4' },
              // ]}
              // text={"When you log in the Dashboard displays...\nPlease select the  page video now\nGo back to '?' anytime"}
              topicVideo={{
                href: USER.VIDEO_URL_SPRINTVIEW,
                text: 'Timeline',
              }}
              onboardingKey={USER.ONBOARDING_SPRINTVIEW_DIALOG}
              closeDialog={this.setOnboardingDialog}
              textOffset={'40px'}
            />
          )}
        </ContentWrapper>
        {!splitWP && showListViewDialog && <ProjectScheduleReport />}
        {splitWP && (
          <SplitWPDialog
            parent={'Sprint'}
            project={project}
            // community={user.community}
            sprintData={sprintData}
          />
        )}
        {this.state.editor.mode === 'person' && (
          <NamePickerDialog
            open={true}
            email={this.state.editor.item ? this.state.editor.item.assignedTo : ''}
            label={
              this.state.editor.item && this.state.editor.item.assignedTo !== ''
                ? `${itemType} currently assigned to`
                : `Assign ${itemType} to`
            }
            toggle={() => {
              this.setAssignmentPickerDialog({ mode: 'off' });
            }}
            team={project.sharedWith}
            projects={projects}
            user={user}
            viewState={viewState}
            userPrefs={userPrefs}
            saveCurrentProject={() => {
              saveCurrentProject();
            }}
            selectTeamMember={newAssignedTo => {
              if (newAssignedTo !== this.state.editor.item.assignedTo) {
                this.state.editor.item.assignedTo = newAssignedTo;
                saveCurrentProject();
                const newViz = { ...this.state.teamVisibility };
                newViz[newAssignedTo] = true;
                this.setState({ teamVisibility: newViz });
                if (this.props.refreshCards) {
                  this.props.refreshCards();
                }
              }
              this.setAssignmentPickerDialog({ mode: 'off' });
            }}
            closeDialog={() => {
              this.setAssignmentPickerDialog({ mode: 'off' });
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(
  state => {
    return {
      viewState: state.viewState,
      workflows: state.workflows,
      projects: state.projects,
      firebase: state.firebase,
      user: state.user,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    reorderColumns,
    updateViewState,
    togglePinVisibility,
    toggleDetailPinVisibility,
    saveCurrentProject,
    dispatchUpdateViewState,
    setSprintPeriod,
    dispatchUpdateUsibilityReport,
  },
)(withStyles(styles)(SprintView));
