import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import * as utils from '../utils/generalUtilities.js';
import NamePicker from './NamePicker';
import Person from '@material-ui/icons/Person';
import InputAdornment from '@material-ui/core/InputAdornment';
import { setUserColleguePref } from '../data/actions/userInterface';

const modalStyles = makeStyles(theme => ({
  namePickerContainer: {
    flexDirection: 'column',
    alignItems: 'stretch',
    margin: '0px 0',
    display: 'flex',
    minWidth: '400px',
    marginLeft: '20px',
    // backgroundColor: 'red',
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'flex',
    margingBottom: '40px',

    // backgroundColor: 'red',
  },
}));
const NEW_MEMBER = 'Add new email';
const NO_MEMBER = 'Close';
const UNASSIGNED = 'Clear assignment';
const ASSIGNEDTO = 'assignedTo';

const NamePickerDialog = ({
  open,
  email,
  label,
  toggle,
  team,
  saveCurrentProject,
  selectTeamMember,
  closeDialog,
  projects,
  user,
  viewState,
  userPrefs,
  setUserColleguePref,
}) => {
  const classes = modalStyles();
  const [teamMember, setTeamMember] = useState({ email: email, useMenu: true, isValidEmail: false });

  const curProject = projects[viewState.currentProjectIndex];

  const addMember = member => {
    const trimmedMember = member.trim();
    team.push(trimmedMember); // updates local values

    if (curProject.sharedWith !== team) {
      curProject.sharedWith.push(trimmedMember);
      projects[viewState.currentProjectIndex] = Object.assign({}, curProject); // copy project to reset immutable tests
    }
    if (!userPrefs.collegues[trimmedMember]) {
      setUserColleguePref({
        email: trimmedMember,
        color: utils.randColor(),
      });
    }
    saveCurrentProject();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        // update # shares in google analytics
        const uniqueShares = {};
        projects.forEach(project => {
          project.sharedWith.forEach(share => {
            uniqueShares[share] = share;
          });
        });
        toggle(false);
      }}
      aria-labelledby="edit-team"
    >
      <div className={classes.bottomContainer}>
        <div className={classes.namePickerContainer}>
          <NamePicker
            field={teamMember}
            fieldLabel={label}
            newEmailLabel={`Please add email for your new team member`}
            project={curProject}
            community={user.community}
            newPersonText={NEW_MEMBER}
            removeText={UNASSIGNED}
            showProjectTeam={true}
            onBlur={inputValue => {
              setTeamMember({
                email: inputValue,
                useMenu: false,
                isValidEmail: utils.isValidEmail(inputValue),
              });
            }}
            cancelButtonClick={() => {
              setTeamMember({ email: '', useMenu: true, isValidEmail: false });
            }}
            addButtonClick={() => {
              if (teamMember.isValidEmail) {
                addMember(teamMember.email);
                selectTeamMember(teamMember.email);
              }
            }}
            onMenuChange={e => {
              if (e.target.value === UNASSIGNED) {
                selectTeamMember('');
                // }
                // if (e.target.value === NO_MEMBER) {
                //   closeDialog();
              } else {
                if (e.target.value === NEW_MEMBER) {
                  setTeamMember({ email: '', useMenu: false, isValidEmail: false });
                } else {
                  if (e.target.value !== teamMember.email) {
                    if (!curProject.sharedWith.includes(e.target.value)) {
                      curProject.sharedWith.push(e.target.value);
                    }
                    selectTeamMember(e.target.value);
                  } else {
                    closeDialog();
                  }
                }
              }
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
              autocomplete: 'off',
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};
export default connect(
  state => {
    return {
      userPrefs: state.userPrefs,
    };
  },
  { setUserColleguePref },
)(NamePickerDialog);
