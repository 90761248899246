/* eslint-disable */

import React, { useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { red, yellow, grey } from '@material-ui/core/colors';

import { useTheme } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Person from '@material-ui/icons/Person';
import Tooltip from '@material-ui/core/Tooltip';

import DroppableWPCardList from './DroppableWPCardList';
import DroppableTaskCardList from './DroppableTaskCardList';
import { AddButton } from '../styled_components/AddButton';
import { InfoButton } from '../styled_components/InfoButton';
import { PinButton } from '../styled_components/PinButton';

import {
  handleMenu,
  dispatchUpdateViewState,
  setUserColleguePref,
  updateViewState,
  setInfoDialog,
  saveCurrentProject,
} from '../../data/actions/userInterface';
import ActionMenu from '../styled_components/ActionMenu';
import { PriorityIcon } from '../Icons/PriorityIcon';

import * as STATUS from '../../constants/status';
import * as NAV from '../../constants/navigation';
import * as TASK from '../../constants/tasks';
import * as constants from '../../constants/app';
import * as utils from '../../utils/generalUtilities';

const assignedToAvatarSize = 30;

const useStyles = makeStyles(theme => ({
  column: {
    flex: 1,
    borderLeft: ({ isSelected, isSelectedColor }) =>
      `solid ${isSelected ? '3.5px' : '3.5px'} ${isSelectedColor}`,
    borderRight: ({ isSelected, isSelectedColor }) =>
      `solid ${isSelected ? '2.5px' : '2.5px'} ${isSelectedColor}`,
    // borderBottom: ({ isSelected, isSelectedColor }) =>
    //   `solid ${isSelected ? '3.0px' : '3.0px'} ${isSelectedColor}`,
    borderTop: ({ topColor }) => `solid 10px ${topColor}`,
    minWidth: '250px',
    maxWidth: '400px',
    marginLeft: '7px',
    marginRight: '8px',
    overflow: 'auto',
    width: '100%',
    cursor: 'pointer',
    minHeight: '70vh',
    background: theme.palette.workCard.wbsColumn,
    // maxHeight: ({ isExpanded }) => (isExpanded ? '80vh' : '60px'),
  },
  columnTop: {
    position: 'sticky',
    height: '8px',
    // background: 'red',
    background: ({ allWPCardsDone, allWPCardsNotStarted }) =>
      `${
        allWPCardsNotStarted
          ? theme.palette.workCard.notStarted
          : allWPCardsDone
          ? theme.palette.workCard.completed
          : theme.palette.workCard.started
      }`,
    //   return topColor

    // },
  },
  columnHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'sticky',
    alignItems: 'flex-start',
    top: 0,
    padding: '15px',
    background: theme.palette.workCard.wbsColumn,
    zIndex: 1,
  },

  titleContents: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // backgroundColor: 'green'
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    // backgroundColor: 'green'
  },
  lowerRightContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addButtonContainer: {
    // marginTop:
    marginLeft: 5,
  },
  infoButton: {
    height: 14,
    alignSelf: 'flex-start',
    marginTop: -5,
    marginRight: 10,
    marginBottom: -5,
  },
  addWPButton: {
    height: 14,
    alignSelf: 'center',
    // backgroundColor: 'green'
    marginTop: -5,
    marginBottom: -5,
  },
  addButton: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    margin: '0 5px',
    '&:hover': {
      // background: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
    '& svg': {
      fontSize: 22, // sets size of IconButton and scales the font inside
    },
    boxShadow: 1,
  },
  delTitle: {
    maxHeight: 50,
    overflow: 'hidden',
  },
  priorityIcon: {
    // marginTop: 4,
    // backgroundColor: 'green'
  },
  rightTitleComponents: {
    flex: 0,
    display: 'flex',
    marginTop: -2,
    marginLeft: 8,
    // position: 'absolute', // to parent as parent position is relative/sticky
    // paddingLeft: 10,
    // top: 12,
    // right: 5,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  colTitle: {
    fontSize: '1rem',
    fontWeight: '500',
    width: '100%',
  },
  subTitleRow: {
    display: 'flex',
    marginTop: -2,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
    marginLeft: 4,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  tooltip: {
    fontSize: 20,
    // marginTop: 5,
    marginLeft: 4,
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },
  numTasks: {
    fontSize: '.78rem',
  },
  dateString: {
    fontSize: '.8rem',
    alignSelf: 'flex-start',
  },
  endDate: {
    marginLeft: 15,
    alignSelf: 'flex-start',
    // backgroundColor: 'blue'
  },
  endDate2: {
    marginTop: -10,
    alignSelf: 'flex-start',
    // backgroundColor: 'blue'
  },
  pinButton: {
    marginTop: 4,
    marginLeft: 5,
  },
}));

let scrollTimer;

// Draggable container for deliverable header & WP card list
const DraggableDelColumn = ({
  delCard,
  index,
  isExpanded,
  viewStack,
  viewState,
  handleMenu,
  dispatchUpdateViewState,
  setUserColleguePref,
  userPrefs,
  onboarding,
  updateViewState,
  setInfoDialog,
  saveCurrentProject,
  workflows,
  setEditor = null,
}) => {
  const draggableId = delCard.uuid;
  const title = delCard.deliverable.name;
  const deliverable = delCard.deliverable;
  const stackTop = viewStack[viewStack.length - 1];
  const isLibraryChange = viewState.curWindow === NAV.LIBRARY_BOARD;
  const inWorkflow = viewState.currentProjectType === 'Workflow';
  const isSelected =
    'p'.includes(stackTop.level) && stackTop.item.d === delCard.index && stackTop.item.w === -1;
  let allWPCardsDone = true;
  let allWPCardsNotStarted = true;
  if (delCard.deliverable.library) {
    allWPCardsDone = false;
    allWPCardsNotStarted = true;
  } else {
    delCard.wpCards.forEach(wpCard => {
      if (wpCard.workpackage.actualStartDate !== '') allWPCardsNotStarted = false;
      if (wpCard.workpackage.actualEndDate === '') allWPCardsDone = false;
    });
  }
  const theme = useTheme();
  const [adviceComponent, setAdviceComponent] = React.useState(() => {
    return utils.getAdviceComponent(workflows, delCard.deliverable.templateRefUUID, delCard.deliverable);
  });
  const topColor = `${
    allWPCardsNotStarted
      ? theme.palette.workCard.wbsColumnTopNotStarted
      : allWPCardsDone
      ? theme.palette.workCard.wbsColumnTopCompleted
      : theme.palette.workCard.wbsColumnTopStarted
  }`;
  const isSelectedColor = `${
    allWPCardsNotStarted
      ? theme.palette.workCard.wbsColumnBorderNotStarted
      : allWPCardsDone
      ? theme.palette.workCard.wbsColumnBorderCompleted
      : theme.palette.workCard.wbsColumnBorderStarted
  }`;

  const classes = useStyles({ isExpanded, isSelected, topColor, isSelectedColor });
  const { currentDeliverable } = viewState;
  const {
    percentageComplete,
    actualStartDate,
    isPriority,
    expectedStartDate,
    expectedEndDate,
    actualEndDate,
    assignedTo,
    numNonSkippedTasks,
    numDoneTasks,
    numInProgressItems,
    workPackages,
    refId,
    name,
  } = deliverable;
  let MENU_ACTIONS = [
    {
      name: 'Open',
      action: `deliverable.${currentDeliverable}.Open`,
      icon: <OpenInNewIcon />,
    },
    {
      name: 'Rename',
      action: `deliverable.${currentDeliverable}.Edit`,
      icon: <EditIcon />,
    },
    {
      name: 'Assign',
      action: {
        open: {
          type: 'd',
          stack: viewStack,
        },
      },
      icon: <Person />,
    },
    {
      name: 'Details',
      action: {
        open: {
          type: 'd',
          stack: viewStack,
        },
      },
      icon: <EditIcon />,
    },
    {
      name: 'Delete',
      action: `deliverable.${currentDeliverable}.Delete`,
      icon: <DeleteIcon />,
    },
    {
      name: 'Cut',
      action: `deliverable.${currentDeliverable}.Cut`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'Copy',
      action: `deliverable.${currentDeliverable}.Copy`,
      icon: <FileCopyIcon />,
    },
    {
      name: 'Paste',
      action: `deliverable.${currentDeliverable}.Paste`,
      icon: <FileCopyIcon />,
    },

    // {
    //   name: 'to Project',
    //   action: `deliverable.${currentDeliverable}.ToProj`,
    //   icon: <PublishIcon />,
    //   tooltip: 'convert this task to a task group'
    // },
  ];
  if (!viewState.copyContent || viewState.copyType !== 'deliverable') MENU_ACTIONS.splice(7, 1); // remove paste option if no copy content
  if (delCard.numSiblings === 1) MENU_ACTIONS.splice(5, 1); // don't allow cut of last deliverable
  // if (!inWorkflow && delCard.status !== STATUS.IN_PROGRESS) {
  //   MENU_ACTIONS.push({
  //     name: delCard.deliverable.pinned ? 'Unpin' : 'Pin',
  //     action: `deliverable.${currentDeliverable}.Pin`,
  //     icon: delCard.deliverable.pinned ? <VisibilityOffIcon /> : <VisibilityOffIcon />,
  //   });
  // }
  const handleDoubleClick = e => {
    if (delCard.cardType !== TASK.DELIVERABLE_CARD) return;

    // alert(`column double clicked`)
    const newStack = viewStack.slice();
    // newStack[-1].item = {   // for now this is a no-op as the only place this is called is from a NAV.BOARD
    //   d: delCard.index,
    //   w: -1,
    //   t: -1,
    // }
    newStack.push({
      screen: NAV.BOARD,
      item: {
        d: delCard.index,
        w: -1,
        t: -1,
      },
      level: 'd',
      zoomDelUID: delCard.deliverable.uuid,
    });
    // newStack[newStack.length - 1].level = 'd'; // redundant
    Object.assign(viewState, {
      currentDeliverable: delCard.index,
      [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
    });
    dispatchUpdateViewState(viewState);
  };
  const avatarId = assignedTo ? assignedTo.substring(0, 1).toUpperCase() : null;

  let newColorAssignment = null;
  useEffect(() => {
    if (newColorAssignment) {
      setUserColleguePref(newColorAssignment);
    }
  });
  let collegueColor;
  if (assignedTo) {
    if (userPrefs.collegues[assignedTo]) {
      collegueColor = userPrefs.collegues[assignedTo].color;
    } else {
      collegueColor = utils.randColor();
      newColorAssignment = {
        email: assignedTo,
        color: collegueColor,
      };
    }
  }

  let percentDone = 0;

  let expectedEndDateOfNonDoneItems;
  let expectedStartDateOfNonDoneItems;
  let numWPs = 0;
  if (workPackages) {
    // to handle library editing
    if (!deliverable.library) {
      percentDone = utils.percentageDeliverableTasksDone(deliverable);
    }

    workPackages.forEach(wp => {
      if (utils.isInProgressStatus(wp.status)) {
        if (wp.actualEndDate === '') {
          // if not done
          if (
            wp.expectedStartDate !== '' &&
            (!expectedStartDateOfNonDoneItems ||
              moment(wp.expectedStartDate).isBefore(moment(expectedStartDateOfNonDoneItems)))
          ) {
            expectedStartDateOfNonDoneItems = wp.expectedStartDate;
          }
          if (
            wp.expectedEndDate !== '' &&
            (!expectedEndDateOfNonDoneItems ||
              moment(wp.expectedEndDate).isAfter(moment(expectedEndDateOfNonDoneItems)))
          ) {
            expectedEndDateOfNonDoneItems = wp.expectedEndDate;
          }
        }
      }
    });
    if (!expectedStartDateOfNonDoneItems) expectedStartDateOfNonDoneItems = '';
    if (!expectedEndDateOfNonDoneItems) expectedEndDateOfNonDoneItems = '';

    workPackages.forEach(wp => {
      if (wp.actualEndDate === '') {
        if (!itemDate) noEndDateItems = true;
        if (wp.expectedEndDate !== '' && (!itemDate || wp.expectedEndDate > itemDate))
          itemDate = wp.expectedEndDate;
      }
    });
    numWPs = workPackages.length;
  }
  const dateStats = utils.getDateStats(expectedEndDateOfNonDoneItems);
  const { daysLeft, isLate, dueSoon } = dateStats;
  let { lateIconColor, tooltipTitle } = utils.getDateStatsInfo(isLate, dueSoon, daysLeft);

  // if not started - add exp start date with tooltip
  // if started - show exp end date (these get flagged if not set)
  // if done - show act end date
  const status =
    actualEndDate !== '' ? STATUS.DONE : actualStartDate !== '' ? STATUS.IN_PROGRESS : STATUS.NOT_STARTED;
  let itemDate = null;
  let noEndDateItems = false;

  let curSprintId = utils.getRelativeSprintId(viewState.numWeeksInSprint);
  const sprintEndDate = utils.sprintId2Date(curSprintId, viewState.numWeeksInSprint);
  const currentSprintStartDate = moment(sprintEndDate)
    .subtract(viewState.numWeeksInSprint * 7 - 3, 'days')
    .format('YYYY-MM-DD');
  const late = itemDate < currentSprintStartDate;

  if (!itemDate) {
    if (noEndDateItems) itemDate = 'No end date';
  } else {
    let itemSprintId = utils.date2SprintId(itemDate, viewState.numWeeksInSprint);
    const [humanSprintStartDate, humanSprintEndDate] = utils.getSprintHumanDates(
      itemSprintId,
      viewState.numWeeksInSprint,
    );
    itemDate = humanSprintEndDate;
  }

  tooltipTitle = !itemDate
    ? ''
    : itemDate !== 'No end date'
    ? late
      ? `overdue - was expected to be completed by ${itemDate}`
      : `will be completed by ${itemDate}`
    : '';
  lateIconColor = itemDate === 'No end date' || late ? red[500] : grey[500];

  if (isNaN(numWPs)) numWPs = 0;
  const wpCountName = numWPs === 1 ? 'task group' : 'task groups';

  const emptyAdvice = adviceComponent ? utils.isInfoEmpty(adviceComponent.advice) : false;

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <Grid
          className={classes.column}
          ref={el => {
            if (el) {
              provided.innerRef(el);
              el.scrollTop =
                viewState.scrollTop.item && viewState.scrollTop.item[deliverable.uuid]
                  ? viewState.scrollTop.item[deliverable.uuid]
                  : 0;
              return el;
            }
          }}
          {...provided.draggableProps}
          onClick={() => {
            const newStack = viewStack.slice();
            newStack[newStack.length - 1].item = {
              // for now this is a no-op as the only place this is called is from a NAV.BOARD
              d: delCard.index,
              w: -1,
              t: -1,
            };
            Object.assign(viewState, {
              currentDeliverable: delCard.index,
              prevWindow: NAV.BOARD,
              [viewStack === viewState.wbsStack ? 'wbsStack' : 'libStack']: newStack,
            });
            dispatchUpdateViewState(viewState);
          }}
          onScroll={e => {
            if (!viewState.scrollTop.item) viewState.scrollTop.item = {};
            if (e.currentTarget.scrollTop !== viewState.scrollTop.item[deliverable.uuid]) {
              if (scrollTimer) {
                clearTimeout(scrollTimer);
              }
              const scrollTop = e.currentTarget.scrollTop;
              scrollTimer = setTimeout(e => {
                // debounce scoll values
                viewState.scrollTop.item[deliverable.uuid] = scrollTop;
                // console.log(`scroll ${scrollTop}`);
                // updateViewState(viewState)
                scrollTimer = undefined;
              }, 50);
            }
          }}
        >
          <div
            className={classes.columnHeader}
            onDoubleClick={handleDoubleClick}
            {...provided.dragHandleProps}
          >
            <div className={classes.titleContents}>
              <div className={classes.titleContainer}>
                <div className={classes.delTitle}>
                  <Typography className={classes.colTitle}>{title}</Typography>
                </div>
                {delCard.cardType === TASK.DELIVERABLE_CARD && (
                  <div className={classes.rightTitleComponents}>
                    <InfoButton
                      handleClick={() => {
                        setInfoDialog({
                          // sets InfoDialog reducer values
                          title: `Deliverable: ${delCard.deliverable.name}`,
                          content: {
                            description: delCard.deliverable.description,
                            tooltip: delCard.deliverable.tooltip ?? constants.DEFAULT_TOOLTIP,
                          },
                          onSave: result => {
                            delCard.deliverable.description = result.description;
                            delCard.deliverable.tooltip = result.tooltip;
                            saveCurrentProject();
                          },
                        });
                      }}
                      size="small"
                      color={theme.palette.primary.infoButton}
                      bgdColor="#FFF0"
                      hoverColor={theme.palette.primary.infoButton + '10'}
                      borderColor={theme.palette.primary.infoButton}
                      tooltip={delCard.deliverable.tooltip ?? constants.DEFAULT_TOOLTIP}
                      containerClassName={classes.infoButton}
                      type={'i'}
                    />
                    {isPriority && (
                      <div className={classes.priorityIcon}>
                        <PriorityIcon />
                      </div>
                    )}
                    {percentDone > 0 && (
                      <Typography variant="overline">
                        {deliverable && `${percentDone.toFixed(0)}%`}
                      </Typography>
                    )}
                    {avatarId ? (
                      <Tooltip title={assignedTo} className={classes.tooltip}>
                        <div className={classes.assignedToAvatar} style={{ backgroundColor: collegueColor }}>
                          <Typography
                            className={classes.assignedToText} //
                            onClick={e => {
                              if (setEditor) {
                                setEditor({
                                  mode: 'person',
                                  point: [Math.max(200, e.pageX - 280), e.pageY - 200],
                                  item: delCard.deliverable,
                                });
                              }
                            }}
                          >
                            {avatarId}
                          </Typography>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={classes.assignedToAvatar} style={{ backgroundColor: '#A0A0A020' }}>
                        <Person
                          className={classes.notAssignedTo}
                          onClick={e => {
                            if (setEditor) {
                              setEditor({
                                mode: 'person',
                                point: [Math.max(200, e.pageX - 280), e.pageY - 200],
                                item: delCard.deliverable,
                              });
                            }
                          }}
                        />
                      </div>
                    )}
                    {isSelected && (
                      <ActionMenu handleMenu={handleMenu} actions={MENU_ACTIONS} viewStack={viewStack} />
                    )}
                  </div>
                )}
              </div>
              {
                <div className={classes.subTitleRow}>
                  {/* <div>
                    <Typography variant="overline" className={classes.numTasks}>
                      {numWPs === 0 ? 'completed' : `${numWPs} ${wpCountName}`}
                    </Typography>
                  </div> */}
                  <div className={classes.lowerRightContainer}>
                    <div className={classes.addButtonContainer}>
                      <AddButton
                        handleClick={() => {
                          let curWPIndex;
                          let location;
                          const stackTop = isLibraryChange
                            ? viewState.libStack[viewState.libStack.length - 1]
                            : viewState.wbsStack[viewState.wbsStack.length - 1];
                          curWPIndex = stackTop.item.w;
                          location = '';
                          switch (delCard.cardType) {
                            default:
                            case TASK.DELIVERABLE_CARD:
                              if (
                                stackTop.item.d !== delCard.index ||
                                curWPIndex === undefined ||
                                curWPIndex < 0
                              ) {
                                curWPIndex = 0;
                                location = '.ABOVE';
                              }
                              handleMenu(`work package.${delCard.index}.${curWPIndex}.Add${location}`);
                              break;
                            case TASK.LIB_TASK_CONTAINER_CARD:
                              // in tasks library column
                              curWPIndex = stackTop.item.w;
                              location = '';
                              if (curWPIndex === undefined || curWPIndex < 0) {
                                curWPIndex = 0;
                                location = '.ABOVE';
                              }
                              handleMenu(`task.${curWPIndex}.Add${location}`);
                              break;
                            case TASK.LIB_WP_CONTAINER_CARD:
                              let curDelIndex = stackTop.item.d;
                              if (curDelIndex === undefined || curDelIndex < 0) {
                                curDelIndex = 0;
                              }
                              curWPIndex = stackTop.item.w;
                              location = '';
                              if (curWPIndex === undefined || curWPIndex < 0) {
                                curWPIndex = 0;
                                location = '.ABOVE';
                              }
                              handleMenu(`work package.${curDelIndex}.${curWPIndex}.Add${location}`);
                              break;
                          }
                        }}
                        size="small"
                        color="#FFF"
                        bgdColor={theme.palette.primary.addButton}
                        hoverColor={theme.palette.secondary.addButton + 'E0'}
                        borderColor="#FFF"
                        tooltip={
                          delCard.cardType === TASK.LIB_TASK_CONTAINER_CARD
                            ? 'add a new task'
                            : 'add a new task group'
                        }
                        containerClassName={classes.addWPButton}
                      />
                    </div>
                  </div>
                </div>
              }
              {/* {itemDate && delCard.status !== STATUS.NOT_STARTED && ( */}
              {itemDate && (
                <div className={classes.subTitleRow}>
                  <div className={classes.endDate2}>
                    <Tooltip title={tooltipTitle}>
                      <Typography
                        variant="overline"
                        className={classes.dateString}
                        style={{ color: lateIconColor }}
                      >
                        {itemDate}
                      </Typography>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
          <DroppableWPCardList
            listId={delCard.uuid}
            wpCards={delCard.wpCards}
            viewStack={viewStack}
            parentContext={NAV.BOARD}
            isLibrary={isLibraryChange}
            setEditor={setEditor}
          />
        </Grid>
      )}
    </Draggable>
  );
};

export default connect(
  state => {
    return {
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    dispatchUpdateViewState,
    setUserColleguePref,
    updateViewState,
    setInfoDialog,
    saveCurrentProject,
  },
)(DraggableDelColumn);
