/* eslint-disable */

import React, { useState, useEffect, useRef, Clipboard } from 'react';
import { connect } from 'react-redux';
import FlatList from 'flatlist-react';
import { FixedSizeList } from 'react-window';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake'; // see https://pdfmake.github.io/
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { saveAssignments } from '../../data/actions/firebase/userAssignments.js';
import { saveCurrentProject, saveWorkflows } from '../../data/actions/userInterface.js';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import { red, yellow, grey, white } from '@material-ui/core/colors';
import { Button, ButtonGroup, Select, MenuItem } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Person from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import EventIcon from '@material-ui/icons/Event';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import NameIcon from '@material-ui/icons/SupervisorAccount';
import { DragDropContext } from 'react-beautiful-dnd';
import { DroppableAssignmentsList } from '../project_overview/DroppableAssignmentsList';
import { rollUpDates } from '../../data/actions/userInterface.js';
import { ContentWrapper } from '../styled_components/ContentWrapper.js';
import { PriorityIcon } from '../Icons/PriorityIcon.js';
import { DateRange } from '@material-ui/icons';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Typography from '@material-ui/core/Typography';
import Share from '@material-ui/icons/Share';

import * as STATUS from '../../constants/status.js';
import { useTheme } from '@material-ui/styles';
import {
  SupervisorAccount,
  Lock,
  Event,
  AccountTree,
  Assignment,
  DoubleArrow,
  Add,
} from '@material-ui/icons';
import { Heading } from '../styled_components/Heading.js';
import { Row } from '../styled_components/Row.js';
import { TeamModal } from './TeamModal.js';
import { InputField } from '../styled_components/InputField.js';
import * as utils from '../../utils/generalUtilities.js';
import {
  dispatchUpdateViewState,
  handleMenu,
  updateViewState,
  setUserColleguePref,
} from '../../data/actions/userInterface.js';
import NamePickerDialog from '../NamePickerDialog.js';
import * as NAV from '../../constants/navigation.js';
import * as USER from '../../constants/user.js';
import * as TASKS from '../../constants/tasks.js';
import { Fragment } from 'react';
import { dispatchUpdateUsibilityReport } from '../../data/actions/app.js';
import { date } from 'yup';

import * as constants from '../../constants/app';

const assignedToAvatarSize = 25;

const projectDashboardStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'block',
    height: window.innerHeight,
  },
  assignmentListContainer: {
    display: 'flex',
    // flex: 0,
    flexDirection: 'column',
    alignSelf: 'stretch',
    marginTop: 10,
    marginBottom: 30,
    paddingLeft: 20,
    paddingRight: 0,
    height: '100vh',
    overflowY: 'hidden',

    // height: '90%',
    // backgroundColor: 'yellow'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    minWidth: '100%',
    marginTop: '-55px',
  },
  parentContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 20,
    // width: 1200,
    marginTop: '5px',
    height: 'calc(100vh - 230px)',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#888',
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    marginLeft: 20,
    marginRight: 20,
  },
  statusButtonRow: {
    flex: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 20,
  },
  absButton: {
    position: 'absolute', // note parent is position: 'relative' to make this abs rel to parent
    right: 15,
    top: 25,
    width: 60,
    height: 30,
    // border: '1px solid #'
  },
  editor: {
    // position: 'absolute', // note parent is position: 'relative' to make this abs rel to parent
    width: 210,
    height: 50,
    backgroundColor: 'white',
    // zIndex: 99,
    // border: '1px solid #'
  },
  editorBox: {
    position: 'absolute', // note parent is position: 'relative' to make this abs rel to parent
    padding: 10,
    backgroundColor: '#ffffffe0',
    borderRadius: 7,
    zIndex: 99,
  },
  urProjectRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // height: 40,
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: '2px',
    marginBottom: '2px',
  },
  dueIcon: {
    fontSize: '1.2rem',
    marginLeft: '0px',
    width: '40px',
  },
  dueText: {
    fontSize: '.9rem',
    marginLeft: '0px',
    marginBottom: '1px',
    width: '140px',
    color: '#555',
  },
  blankDueText: {
    fontSize: '.9rem',
    marginLeft: '0px',
    marginBottom: '1px',
    width: '180px',
    color: '#555',
  },
  alertComponentText: {
    flex: 1,
    fontSize: '1.1rem',
    marginLeft: '0px',
    marginTop: '-2px',
    marginBottom: '5px',
    // fontWeight: 600,
    color: '#555',
    cursor: 'pointer',
    '&:hover': {
      // background: theme.palette.secondary.main,
      color: 'blue',
      textDecoration: 'underline',
    },
  },
  reportTitle: {
    alignSelf: 'center',
    fontSize: '1.4rem',
    marginLeft: '0px',
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    color: '#666',
  },
  buttonTitle: {
    alignSelf: 'flex-start',
    fontSize: '1rem',
    marginRight: '1px',
    fontWeight: 600,
    color: '#666',
  },
  reportSubTitle: {
    alignSelf: 'center',
    fontSize: '1rem',
    marginLeft: '0px',
    marginTop: '5px',
    marginBottom: '10px',
    fontWeight: 600,
    color: '#6164C9',
  },
  reportlist: {
    marginLeft: '0px',
    overflowY: 'hidden',
    height: 'calc(100vh - 250px)',
  },
  clipButton: {
    marginTop: '-7px',
  },
  pdfButton: {
    marginTop: '-7px',
    color: '#6164C9',
    padding: '0px',
    // backgroundColor: 'yellow'
  },
  attentionButton: {
    minWidth: 70,
  },
  attentionButtonGroup: {
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  priorityButtonGroup: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: 10,
    marginRight: 20,
  },
  statusButtonGroup: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: 20,
    marginRight: 10,
  },
  assignedToText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  notAssignedTo: {
    color: '#A0A0A040',
  },

  assignedToAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: assignedToAvatarSize / 2,
    height: assignedToAvatarSize,
    width: assignedToAvatarSize,
  },
  assignedToAvatarText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 700,
    alignSelf: 'center',
  },
  statusFilterButton: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    alignSelf: 'center',
    padding: '1px 8px 1px 8px',
    // minWidth: '100px',
    minWidth: '100px',
    // maxWidth: '85px',
    // width: '400px',
    height: '42px',
    border: '1px solid #CCC',
    borderRadius: '12px',
  },
  statusFilterButtonText: {
    flex: 0,
    color: 'white',
    fontSize: 15,
    fontWeight: 700,
    alignText: 'center',
  },
  statusFilterCountText: {
    flex: 0,
    color: 'white',
    fontSize: 14,
    fontWeight: 700,
    alignText: 'center',
  },
  attentionMessage: {
    // margin: theme.spacing(2),
    color: '#888',
  },
  loadingIndicator: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 200,
    // backgroundColor: 'yellow'
  },
  statusButton: {
    color: ({ buttonColor }) =>
      // theme.palette.getContrastText(buttonColor),
      '#222',
    backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    '&:hover': {
      backgroundColor: ({ buttonColor }) => `${buttonColor}`,
    },
    width: '120px',
  },
  taskDate: {
    // maxHeight: '.9em',
    marginTop: -6,
    marginRight: 10,
    // backgroundColor: 'yellow'
  },
  dateString: {
    fontSize: '.9em',
  },
  input: {
    width: '240px',
    marginRight: '10px',
    marginBottom: '15px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: '20px',
    width: '150px',
    '& .MuiInputBase-input': {
      // borderRadius: 4,
      position: 'relative',
      // border: "1px solid red",
      fontSize: 16,
      padding: '7px 10px 7px 10px',
      marginRight: '15px',
    },
  },
  assignSubTitle: {
    alignSelf: 'center',
    fontSize: '1rem',
    marginLeft: '40px',
    marginTop: '5px',
    marginBottom: '0px',
    fontWeight: 500,
    color: '#666',
  },
  shareIcon: {
    height: '30px',
    width: '30px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #aaa',
    borderRadius: '5px',
    marginLeft: '30px',
    padding: '2px',
    color: '#888',
    backgroundColor: '#f6f6f6',
    zIndex: 10,
  },
}));

let scrollTimer;
let projectRows = []; // flatten data into rows

const NOT_ASSIGNED = 'not assigned';
const NOT_ASSIGNED_BKGD_COLOR = '#A0A0A020';

const ProjectDashboard = ({
  user,
  handleProjectInfoChange,
  projects,
  workflows,
  viewState,
  dispatchUpdateViewState,
  saveWorkflows,
  handleMenu,
  dispatchUpdateUsibilityReport,
  onboarding,
  userPrefs,
  rollUpDates,
  saveCurrentProject,
  saveAssignments,
  setUserColleguePref,
}) => {
  const classes = projectDashboardStyles();
  const theme = useTheme();
  const curProject = projects[viewState.currentProjectIndex];
  if (!curProject.assignedTo) curProject.assignedTo = curProject.projectManager;

  const teamSort = (a, b) => {
    if (a.email < b.email) return -1;
    if (a.email > b.email) return 1;
    return 0;
  };
  const listContainerRef = useRef(null);

  const [editor, setEditor] = React.useState({ mode: 'off' });
  const getVisibleTeamMembersLookup = viewState => {
    if (
      viewState.projectScheduleReport.projectTeams &&
      viewState.projectScheduleReport.projectTeams[curProject.uuid]
    ) {
      return viewState.projectScheduleReport.projectTeams[curProject.uuid].reduce((acc, item) => {
        acc[item.email] = item.visible;
        return acc;
      }, {});
    } else {
      return {};
    }
  };
  const [visibleTeamMembersLookup, setVisibleTeamMembersLookup] = React.useState(
    getVisibleTeamMembersLookup(viewState),
  );

  const genVisibleTeamInfo = (oldVal = {}) => {
    const memberExistsInList = {};
    const teamMembers = [{ email: curProject.creator, visible: oldVal[curProject.creator] ?? true }];
    memberExistsInList[curProject.creator] = true;
    curProject.sharedWith.forEach(member => {
      if (!memberExistsInList[member]) {
        teamMembers.push({ email: member, visible: oldVal[member] ?? true });
        memberExistsInList[member] = true;
      }
    });
    if (curProject.sponsor && curProject.sponsor !== '' && !memberExistsInList[curProject.sponsor]) {
      teamMembers.push({ email: curProject.sponsor, visible: oldVal[curProject.sponsor] ?? true });
      memberExistsInList[curProject.sponsor] = true;
    }
    if (
      curProject.projectManager &&
      curProject.projectManager !== '' &&
      !memberExistsInList[curProject.projectManager]
    ) {
      teamMembers.push({
        email: curProject.projectManager,
        visible: oldVal[curProject.projectManager] ?? true,
      });
      memberExistsInList[curProject.projectManager] = true;
    }
    teamMembers.sort(teamSort);
    teamMembers.push({ email: NOT_ASSIGNED, visible: oldVal[NOT_ASSIGNED] ?? true });
    return teamMembers;
  };

  const setTeamMemberIsVisible = newTeamVisibility => {
    viewState.projectScheduleReport.projectTeams[curProject.uuid] = newTeamVisibility;
    setVisibleTeamMembersLookup(getVisibleTeamMembersLookup(viewState));
    dispatchUpdateViewState({ ...viewState });
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    if (
      !viewState.projectScheduleReport.projectTeams[curProject.uuid] ||
      !viewState.projectScheduleReport.projectTimeFilters[curProject.uuid]
    ) {
      viewState.projectScheduleReport.projectTeams[curProject.uuid] = genVisibleTeamInfo();
      viewState.projectScheduleReport.projectTimeFilters[curProject.uuid] = TASKS.TIME_FILTER_OFF.name;
      setVisibleTeamMembersLookup(getVisibleTeamMembersLookup(viewState));
      dispatchUpdateViewState({ ...viewState });
    }

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const keyDownHandler = event => {
    if (
      event.key === 'Tab' &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      viewState.currentProjectType === NAV.TYPE_WORKFLOW
    ) {
      // handleMenu(`workflow.${viewState.currentWorkflowIndex}.Indent`);
      handleMenu(`workflow.${viewState.currentWorkflowSetId}.${viewState.currentWorkflowIndex}.Indent`);
    }
    if (
      event.key === 'Tab' &&
      !event.metaKey &&
      !event.ctrlKey &&
      event.shiftKey &&
      viewState.currentProjectType === NAV.TYPE_WORKFLOW
    ) {
      // handleMenu(`workflow.${viewState.currentWorkflowIndex}.Outdent`);
      handleMenu(`workflow.${viewState.currentWorkflowSetId}.${viewState.currentWorkflowIndex}.Outdent`);
    }
  };

  const projAssignments = utils.getAssignments(
    [curProject],
    null,
    viewState.assignmentOrder && viewState.assignmentOrder.projects
      ? viewState.assignmentOrder.projects[curProject.uuid]
      : [],
  ); // get all needed and not done assignments across the curProject
  // const projAssignments = utils.getAssignments([curProject], visibleTeamMembersLookup, []);

  const generateTeamMemberAvatarButton = (teamMemberEmail, visible) => {
    if (
      teamMemberEmail !== NOT_ASSIGNED &&
      (!userPrefs.collegues[teamMemberEmail] || !userPrefs.collegues[teamMemberEmail].color)
    ) {
      userPrefs.collegues[teamMemberEmail] = utils.randColor();
      setTimeout(() => {
        setUserColleguePref({
          email: teamMemberEmail,
          color: utils.randColor(),
        });
      });
    }
    const avatarBkgdColor =
      teamMemberEmail === NOT_ASSIGNED || !userPrefs.collegues[teamMemberEmail]?.color
        ? visible
          ? NOT_ASSIGNED_BKGD_COLOR.substring(0, 7) + 'D0'
          : NOT_ASSIGNED_BKGD_COLOR.substring(0, 7) + '30'
        : visible
        ? userPrefs.collegues[teamMemberEmail].color
        : userPrefs.collegues[teamMemberEmail].color.substring(0, 7) + '30';

    return (
      <Tooltip title={teamMemberEmail}>
        <div
          className={classes.assignedToAvatar}
          style={{ backgroundColor: avatarBkgdColor, marginLeft: '10px', userSelect: 'none' }}
          onClick={e => {
            const newTeamVisibility = viewState.projectScheduleReport.projectTeams[curProject.uuid]?.slice();
            if (e.shiftKey) {
              newTeamVisibility.forEach(item => {
                if (item.email === teamMemberEmail) item.visible = true;
                else item.visible = false;
              });
            } else {
              const item = newTeamVisibility.find(item => item.email === teamMemberEmail);
              item.visible = !item.visible;
            }
            setTeamMemberIsVisible(newTeamVisibility);
          }}
        >
          <Typography className={classes.assignedToAvatarText}>
            {teamMemberEmail === NOT_ASSIGNED ? '-' : teamMemberEmail.substring(0, 1).toUpperCase()}
          </Typography>
        </div>
      </Tooltip>
    );
  };
  const teamVisibility =
    viewState.projectScheduleReport.projectTeams &&
    viewState.projectScheduleReport.projectTeams[curProject.uuid]
      ? viewState.projectScheduleReport.projectTeams[curProject.uuid]
      : [];

  return (
    <div className={classes.rootContainer}>
      <div
        style={{
          position: 'absolute',
          top: '66px',
          bottom: 0,
          right: 0,
          left: 50,
          maxHeight: 'calc(100vh -  66px)',
          overflow: 'hidden',
          backgroundColor: '#BBB6',
          // backgroundColor: 'red',
          zIndex: 20,
        }}
      >
        <div
          style={{
            margin: '10px',
            padding: '10px',
            // paddingBottom: '30px',
            marginLeft: '20px',
            marginRight: '10px',
            border: '8px solid #BBB',
            height: 'calc(100vh - 110px)',
            overflow: 'hidden',
            borderRadius: '15px',
            backgroundColor: 'white',
            flex: 1,
          }}
        >
          <ContentWrapper
            viewState={viewState}
            dispatchUpdateViewState={dispatchUpdateViewState}
            title="Assignments"
            headerMarginTop={'10px'}
            titleChildren={
              <Fragment>
                <Typography variant={'body1'} className={classes.assignSubTitle}>
                  {'in the current or earlier work periods'}
                </Typography>
                <Tooltip title={'copy and share'}>
                  <div
                    className={classes.shareIcon}
                    onClick={e => {
                      // toggleAttachmentsOpen();
                      // let htmlCopy = `<!DOCTYPE html>
                      // <html><head>
                      // <style>
                      // table {
                      //   font-family: arial, sans-serif;
                      //   border-collapse: collapse;
                      //   width: 100%;
                      // }

                      // td, th {
                      //   border: 1px solid #dddddd;
                      //   text-align: left;
                      //   padding: 8px;
                      // }

                      // tr:nth-child(even) {
                      //   background-color: #dddddd;
                      // }
                      // </style>
                      // </head>
                      // <body>
                      //   <table>
                      //     <tr>
                      //       <th>ID</th>
                      //       <th>Task Description</th>
                      //       <th>Assigned To</th>
                      //     </tr>`;

                      let textCopy = `${'ID'.padEnd(15, ' ')}\t${'Task'.padEnd(
                        45,
                        ' ',
                      )}\t${'Assigned To'.padEnd(25, ' ')}\t${'Status'}\n`;
                      projAssignments.assignmentInfo.map((assignment, index) => {
                        const task = assignment.taskObj;
                        const assignedTo = assignment.taskObj.assignedTo;
                        if (
                          !visibleTeamMembersLookup ||
                          visibleTeamMembersLookup[assignedTo] ||
                          ((assignedTo === '' || assignedTo === undefined) &&
                            visibleTeamMembersLookup[NOT_ASSIGNED])
                        ) {
                          console.log(`copying assignment ${assignedTo}`);
                          textCopy += `${task.refId.padEnd(15, ' ')}\t${task.name
                            .substring(0, 40)
                            .padEnd(45, ' ')}\t${
                            assignedTo
                              ? assignedTo.substring(0, 22).padEnd(25, ' ')
                              : 'not assigned'.padEnd(25, ' ')
                          }\t${task.status}\n`;
                          // htmlCopy += `<tr>
                          //     <td>${task.refId}</td>
                          //     <td>${task.name}</td>
                          //     <td>${assignedTo}</td>
                          //   </tr>`;
                        }
                      });
                      // htmlCopy += `</table></body></html>`;
                      navigator.clipboard.writeText(textCopy);
                      setTimeout(() => {
                        alert(`Assignements List copied to clipboard.  Please paste it where you like.`);
                      }, 0);
                    }}
                  >
                    <Share />
                  </div>
                </Tooltip>
              </Fragment>
            }
          >
            <div className={classes.contentContainer}>
              <div className={classes.buttonContainer}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  {teamVisibility.map(item => {
                    return generateTeamMemberAvatarButton(item.email, item.visible);
                  })}
                </div>
              </div>
              <div className={classes.parentContainer}>
                {false ? (
                  <div className={classes.loadingIndicator}>
                    <Typography
                      variant={'h6'}
                      className={classes.attentionMessage}
                      style={{ fontWeight: 600 }}
                    >
                      {'Toggle the filter button(s) above to display items.'}
                    </Typography>
                  </div>
                ) : (
                  <div
                    className={classes.reportlist}
                    ref={r => {
                      if (r) {
                        r.scrollTop = viewState.scrollTop.projectSchedule
                          ? viewState.scrollTop.projectSchedule
                          : 0;
                      }
                    }}
                    onScroll={e => {
                      if (e.currentTarget.scrollTop !== viewState.scrollTop.projectSchedule) {
                        if (scrollTimer) {
                          clearTimeout(scrollTimer);
                        }
                        const scrollTop = e.currentTarget.scrollTop;
                        scrollTimer = setTimeout(e => {
                          // debounce scoll values
                          viewState.scrollTop.projectSchedule = scrollTop;
                          updateViewState(viewState);
                          scrollTimer = undefined;
                        }, 50);
                      }
                    }}
                  >
                    <div
                      ref={ref => (listContainerRef.current = ref)} //
                      className={classes.assignmentListContainer}
                    >
                      <DroppableAssignmentsList
                        assignments={projAssignments}
                        userFilter={visibleTeamMembersLookup}
                        listId={'Workflows'}
                        workflows={workflows}
                        templateSetId={constants.PUBLIC_GROUP_ID}
                        saveWorkflows={saveWorkflows}
                        showAssignment={true}
                        setEditor={setEditor}
                        assignmentListHeight={
                          listContainerRef.current ? listContainerRef.current.clientHeight : undefined
                        }
                        saveAssignmentOrder={taskOrder => {
                          const newAssignmentOrder = {
                            ...viewState.assignmentOrder,
                            projects: {
                              ...viewState.assignmentOrder.projects,
                              [curProject.uuid]: taskOrder,
                            },
                          };
                          saveAssignments(newAssignmentOrder);
                        }}
                      />
                    </div>

                    <div style={{ height: '400px' }} />
                  </div>
                )}
              </div>
            </div>
            {editor.mode === 'person' && (
              <NamePickerDialog
                open={true}
                email={editor.item ? editor.item.assignedTo : ''}
                label={
                  editor.item && editor.item.assignedTo !== ''
                    ? `${editor.item.type} currently assigned to`
                    : `Assign ${editor.item.type} to`
                }
                toggle={() => {
                  setEditor({ mode: 'off' });
                }}
                team={curProject.sharedWith}
                projects={projects}
                user={user}
                viewState={viewState}
                userPrefs={userPrefs}
                saveCurrentProject={() => {
                  saveCurrentProject();
                }}
                selectTeamMember={newAssignedTo => {
                  if (editor.item === curProject) {
                    if (newAssignedTo !== editor.item.projectManager) {
                      editor.item.projectManager = newAssignedTo;
                      editor.item.assignedTo = newAssignedTo;
                      saveCurrentProject();
                      visibleTeamMembersLookup[newAssignedTo] = true;
                      setTeamMemberIsVisible(genVisibleTeamInfo(visibleTeamMembersLookup));
                    }
                  } else {
                    if (newAssignedTo !== editor.item.assignedTo) {
                      editor.item.assignedTo = newAssignedTo;
                      saveCurrentProject();
                      visibleTeamMembersLookup[newAssignedTo] = true;
                      setTeamMemberIsVisible(genVisibleTeamInfo(visibleTeamMembersLookup));
                    }
                  }
                  setEditor({ mode: 'off' });
                }}
                closeDialog={() => {
                  setEditor({ mode: 'off' });
                }}
              />
            )}
          </ContentWrapper>
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      user: state.user,
      projects: state.projects,
      workflows: state.workflows,
      viewState: state.viewState,
      firebase: state.firebase,
      onboarding: state.onboarding,
      userPrefs: state.userPrefs,
    };
  },
  {
    handleMenu,
    saveCurrentProject,
    saveAssignments,
    saveWorkflows,
    dispatchUpdateViewState,
    dispatchUpdateUsibilityReport,
    rollUpDates,
    setUserColleguePref,
  },
)(ProjectDashboard);
