import React, { useEffect } from 'react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import DraggableAssignmentCard from './DraggableAssignmentCard';
import DraggableWorkflowCard from './DraggableWorkflowCard';
import * as utils from '../../utils/generalUtilities.js';
import Typography from '@material-ui/core/Typography';

const NOT_ASSIGNED = 'not assigned';
const NOT_ASSIGNED_BKGD_COLOR = '#A0A0A020';

// Droppable container for Dragable WP cards
export const DroppableAssignmentsList = ({
  assignments,
  listId = 'LIST',
  workflows, // all
  showAssignment = false,
  setEditor = null,
  saveWorkflows,
  templateSetId,
  saveAssignmentOrder,
  userFilter = null,
  assignmentListHeight,
}) => {
  const [curAssignments, setCurAssignments] = React.useState(assignments);

  useEffect(() => {
    setCurAssignments(assignments);
  }, [assignments]);

  const onDragEnd = result => {
    // console.log(`drag ended - do something`);
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const newAssignments = [...curAssignments.assignmentInfo];
    const movedAssignment = newAssignments.splice(source.index, 1);
    newAssignments.splice(
      destination.index,
      // source.index < destination.index ? destination.index - 1 : destination.index,
      0,
      movedAssignment[0],
    );
    const assignedTaskIds = newAssignments.reduce((acc, info) => {
      acc.push(info.taskId);
      return acc;
    }, []);
    setCurAssignments({ assignmentInfo: newAssignments, assignedTaskIds: assignedTaskIds });
    saveAssignmentOrder(assignedTaskIds);
  };
  let count = 0;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'assign'} type={'group'}>
        {(dropProvided, dropSnapshot) => {
          // console.log(`rendering DroppableAssignmentsList... `);
          return (
            <div
              ref={dropProvided.innerRef} //
              {...dropProvided.droppableProps}
              style={{
                flex: 1,
                // height: `${assignmentListHeight + 200}px`,
                paddingBottom: '600px',
                overflowY: 'scroll',
                // backgroundColor: 'yellow',
              }}
            >
              {curAssignments.assignmentInfo.map((assignment, index) => {
                const assignedTo = assignment.taskObj.assignedTo;
                if (
                  !userFilter ||
                  userFilter[assignedTo] ||
                  ((assignedTo === '' || assignedTo === undefined) && userFilter[NOT_ASSIGNED])
                ) {
                  count += 1;
                  return (
                    <DraggableAssignmentCard
                      key={`al-${assignment.taskId}-${index}`}
                      assignment={assignment}
                      index={index}
                      setEditor={setEditor}
                      showAssignment={showAssignment}
                    />
                  );
                } else {
                  // don't render items that don't match the filter
                  if (index === curAssignments.assignmentInfo.length - 1 && count === 0) {
                    // if (userFilter) {
                    return (
                      <Typography
                        variant={'h6'}
                        style={{ fontWeight: 600, color: '#888', textAlign: 'center', marginTop: '100px' }}
                      >
                        {'Toggle the team filter button(s) above to display items.'}
                      </Typography>
                    );
                  } else {
                    return null;
                  }
                }
              })}
              {dropProvided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
